<template>
    <Layout>
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/dashboard`"
        />

        <div v-if="quiz && quiz.length" class="pageContent backgroundZircon testWrapper">
            <div class="profileBox profileBox--2">
                <div v-if="!isVisibleAnswer">
                    <p class="profileBox__title2">{{ title }}</p>
                    <p class="profileBox__text2">
                        <span v-if="testType === 'entrance_test'">
                            Благодаря этому тесту у Вас будет возможность сравнить свои
                            знания до и после обучения, а также мы поймём какие материалы
                            будут наиболее эффективны для Вас. На прохождение у Вас есть
                            всего 1 попытка, после которой Вы увидите свой результат,
                            поэтому будьте внимательны.
                        </span>
                        <span v-else-if="testType === 'final_test'">
                            Благодаря этому тесту у Вас будет возможность сравнить свои
                            знания после обучения с теми, которые были в начале. На
                            прохождение у Вас есть всего 1 попытка, после которой Вы
                            увидите свой результат, поэтому будьте внимательны.
                        </span>
                    </p>
                    <Button
                        class="profileBox__btn2"
                        title="Начать"
                        :onClick="() => onSendAnswer()"
                    />
                </div>

                <div v-if="isVisibleAnswer && !isVisibleNextTest">
                    <div class="testProgress">
                        <div class="testProgressHeader">
                            <p class="title1 testProgressHeader__text">Прогресс</p>
                            <p class="title1 testProgressHeader__text">
                                Осталось {{ quiz.length - currentNumber }} вопросов
                            </p>
                        </div>
                        <div class="progressRail testProgressRail">
                            <div
                                class="progressRail__total testProgressRail__total"
                                :style="
                                    'width:' + (currentNumber / quiz.length) * 100 + '%'
                                "
                            ></div>
                        </div>
                    </div>

                    <p class="profileBox__title2">
                        {{ currentAnswer.description }}
                    </p>
                    <div class="form form--2 testForm">
                        <div
                            v-for="(item, index) in currentAnswer.alternatives"
                            :key="item.answer"
                            class="formgroup"
                        >
                            <label class="formgroup__radioLabel">
                                <input
                                    class="input--checkbox"
                                    :value="item._id"
                                    :name="'answer_' + currentNumber"
                                    :type="
                                        currentAnswer.type === 'multiple'
                                            ? 'checkbox'
                                            : 'radio'
                                    "
                                    v-model="
                                        $data[
                                            currentAnswer.type === 'multiple'
                                                ? 'testAnswerCheckbox'
                                                : 'testAnswerRadio'
                                        ]
                                    "
                                />
                                <span class="text formgroup__radioLabel_text">
                                    {{ item.text }}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="profileBox__btn2 testFooter">
                        <Button title="Следующий вопрос" :onClick="() => onNextTest()" />
                    </div>
                </div>

                <div v-if="isVisibleNextTest && !isVisibleResult">
                    <div class="testResult">
                        <div class="testResult__title">
                            <p class="profileBox__title2">
                                Тест пройден!
                            </p>
                            <p
                                v-if="testType === 'entrance_test'"
                                class="profileBox__text2"
                            >
                                Мы сохраним ответы, чтобы Вы <br />могли сравнить
                                результаты в конце обучения.
                            </p>
                            <Button
                                v-else-if="testType === 'final_test'"
                                class="profileBox__btn6"
                                title="Сравнить результаты"
                                :isLink="true"
                                :link="
                                    `/${$route.params.slug}/dashboard/tests-comparison`
                                "
                            />
                        </div>
                        <div class="testResult__correctAnswers">
                            <p class="h1">
                                {{ correctAnswers }}
                                <span class="colorGray"> из {{ quiz.length }} </span>
                            </p>
                            <p class="colorGray testResult__correctAnswers_text">
                                правильных ответов
                            </p>
                        </div>
                    </div>

                    <div v-for="(quizItem, index) in quiz" :key="quizItem._id">
                        <p class="profileBox__title2 profileBox__title2--3">
                            {{ quizItem.description }}
                        </p>
                        <div class="form form--2 testResultForm">
                            <div
                                v-for="(alternative, subindex) in quizItem.alternatives"
                                :key="alternative._id"
                                class="formgroup"
                            >
                                <label
                                    :class="
                                        `formgroup__radioLabel ${
                                            answeredQuestions[index].answers.filter(
                                                function(e) {
                                                    return e.text === alternative.text;
                                                }
                                            ).length > 0
                                                ? answeredQuestions[index].answers.filter(
                                                      function(e) {
                                                          return (
                                                              e.text === alternative.text
                                                          );
                                                      }
                                                  )[0].isCorrect
                                                    ? 'formgroup__radioLabel--success'
                                                    : 'formgroup__radioLabel--wrong'
                                                : ''
                                        }`
                                    "
                                >
                                    <span
                                        v-if="
                                            answeredQuestions[index].answers.filter(
                                                function(e) {
                                                    return e.text === alternative.text;
                                                }
                                            ).length > 0
                                        "
                                    >
                                        <img
                                            v-if="
                                                answeredQuestions[index].answers.filter(
                                                    function(e) {
                                                        return (
                                                            e.text === alternative.text
                                                        );
                                                    }
                                                )[0].isCorrect
                                            "
                                            class="formgroup__radioLabel_img"
                                            alt="done"
                                            src="@/assets/img/pages/study/done.svg"
                                        />
                                        <img
                                            v-else
                                            class="formgroup__radioLabel_img"
                                            alt="done"
                                            src="@/assets/img/pages/study/wrong.svg"
                                        />
                                    </span>
                                    <input
                                        class="input--checkbox"
                                        type="radio"
                                        disabled
                                    />
                                    <span>{{ alternative.text }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Button
                v-if="isVisibleNextTest && !isVisibleResult"
                class="profileBox__btn5"
                title="перейти На дашборд"
                :isLink="true"
                :link="'/' + $route.params.slug + '/dashboard'"
                :onClick="() => anonymousClick()"
            />
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import PageHeader from "@/views/partials/pageHeader/pageHeader";

import TextArea from "@/views/components/TextArea/TextArea";
import Input from "@/views/components/Input/Input";
import Button from "@/views/components/Button/Button";

export default {
    name: "dashboardTest",
    components: {
        Layout,
        PageHeader,
        TextArea,
        Input,
        Button,
    },
    props: {},

    computed: {
        title: function() {
            return this.$route.name || "";
        },

        items: function() {
            return [
                {
                    text: "личный дашборд",
                    to: "/" + this.$route.params.slug + "/dashboard",
                },
                {
                    text: this.title,
                    active: true,
                },
            ];
        },

        testType: function() {
            return this.$route.path.includes("opening-test")
                ? "entrance_test"
                : "final_test";
        },

        member: function() {
            return (
                (this.$store.getters.getMember && this.$store.getters.getMember.data) ||
                {}
            );
        },

        quiz: function() {
            return this.testType === "entrance_test"
                ? (this.$store.getters.getEntranceQuiz &&
                      this.$store.getters.getEntranceQuiz.data) ||
                      {}
                : (this.$store.getters.getFinalQuiz &&
                      this.$store.getters.getFinalQuiz.data) ||
                      {};
        },

        currentAnswer: function() {
            return this.quiz[this.currentNumber];
        },

        isPassedTest: function() {
            return this.testType === "entrance_test"
                ? (this.member &&
                      this.member.passedEntranceTest &&
                      this.member.passedEntranceTest.isPassed) ||
                      false
                : (this.member && !this.member.showFinalQuiz) || false;
        },
    },

    async beforeMount() {
        this.updateMemberStore();
        this.updateQuizStore(this.testType);

        if (this.isPassedTest) {
            await this.$router.push("/" + this.$route.params.slug + "/dashboard");
        }
    },

    data() {
        return {
            isVisibleResult: false,
            isVisibleAnswer: false,
            isVisibleNextTest: false,
            isChecked: "",

            data: {
                answers: [],
            },

            testAnswerRadio: "",
            testAnswerCheckbox: [],

            currentNumber: 0,
            correctAnswers: 0,

            answeredQuestions: [],
        };
    },
    methods: {
        async updateMemberStore() {
            const getMemberData = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study"
            );
            if (getMemberData && getMemberData.data && getMemberData.data.result) {
                if (getMemberData.data.code === "programs") {
                    await this.$store.dispatch("clearMember");
                    await this.$router.push("/");
                } else {
                    await this.$store.dispatch("setMember", {
                        data: getMemberData.data,
                    });
                }
            } else {
                console.error(getMemberData.data.error);
                await this.$store.dispatch("clearMember");
                await this.$router.push("/");
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text:
                            getMemberData.data.error === "Пользователь не найден"
                                ? "В момент заморозки личный кабинет недоступен, но после ее окончания Вы с лёгкостью сможете вернуться в кабинет и продолжить обучение"
                                : getMemberData.data.error,
                    },
                });
            }
        },

        async updateQuizStore(group) {
            const getQuizData = await this.axios.get(
                "/programs/" +
                    this.$route.params.slug +
                    "/quiz-course/questions?group=" +
                    group
            );

            if (getQuizData && getQuizData.data && getQuizData.data.result) {
                if (group === "entrance_test") {
                    await this.$store.dispatch("setEntranceQuiz", {
                        data: getQuizData.data.questions,
                    });
                } else if (group === "final_test") {
                    await this.$store.dispatch("setFinalQuiz", {
                        data: getQuizData.data.questions,
                    });
                }
            } else {
                if (getQuizData && getQuizData.data && getQuizData.data.message)
                    console.error(getQuizData.data.message);
                if (group === "entrance_test") {
                    await this.$store.dispatch("clearEntranceQuiz");
                } else if (group === "final_test") {
                    await this.$store.dispatch("clearFinalQuiz");
                }
            }
        },

        onSendAnswer() {
            this.isVisibleAnswer = true;
        },

        async onNextTest() {
            if (
                (this.testAnswerCheckbox && this.testAnswerCheckbox.length) ||
                this.testAnswerRadio
            ) {
                let value = [];
                if (this.testAnswerRadio) {
                    value.push(this.testAnswerRadio);
                } else if (this.testAnswerCheckbox && this.testAnswerCheckbox.length) {
                    value = this.testAnswerCheckbox;
                }

                this.data.answers.push({
                    question: this.currentAnswer._id,
                    alternatives: value,
                });

                if (this.currentNumber + 1 < this.quiz.length) {
                    this.currentNumber++;
                } else {
                    const response = await this.axios.post(
                        "/programs/" +
                            this.$route.params.slug +
                            "/quiz-course/validate?group=" +
                            this.testType +
                            "&memberId=" +
                            this.member.member__id,
                        this.data
                    );

                    if (response && response.data && response.data.result) {
                        this.correctAnswers = response.data.countCorrectAnswers;
                        this.answeredQuestions = response.data.questions;
                        this.isVisibleNextTest = true;
                    }
                }

                this.testAnswerRadio = "";
                this.testAnswerCheckbox = [];
            } else {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Вы не выбрали ни одного ответа",
                    },
                });
            }
        },

        handleClickRadioButton(value) {
            this.isChecked = value;
        },
    },
};
</script>

<style lang="scss">
@import "./dashboardTest.scss";
</style>
